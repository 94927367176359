<template>
  <div class="qr-code-styling">
    <div id="qr-code-content" t-data="qr-code-content">
      <div id="qr-code" ref="qrCode" class="d-flex justify-center pa-6"></div>
      <div id="qr-code-detail" class="body-regular primaryTextColor--text">
        <span class="body-bold title-detail">Branch : </span
        >{{ rowItem.org.name }}<br />
        <span class="body-bold title-detail">Initials : </span
        >{{ rowItem.org_codename.toUpperCase() }}
      </div>
    </div>
    <div class="d-flex justify-center mt-6">
      <v-btn
        v-if="showBtn"
        class="button-bold mr-4 print-not-display"
        t-data="download-btn"
        color="whiteColor"
        @click="download"
      >
        Download
      </v-btn>
      <v-btn
        v-if="showBtn"
        class="button-bold white-text-button print-not-display"
        t-data="print-btn"
        color="primary600Color"
        @click="print"
      >
        Print
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import QRCodeStyling from 'qr-code-styling'
import html2canvas from 'html2canvas'

export default {
  name: 'QrCodeStyling',
  props: {
    rowItem: {type: Object},
    options: {type: Object},
    showBtn: {type: Boolean, required: false, default: false}
  },
  mounted() {
    this.qrCode = new QRCodeStyling(this.options)
    this.qrCode.append(this.$refs['qrCode'])
    this.qrCodeClone = new QRCodeStyling({
      ...this.cloneOptions,
      ...{data: this.options.data}
    })
  },
  watch: {
    ['options.data']: function() {
      this.qrCode.update(this.options)
      this.qrCodeClone.update({
        ...this.cloneOptions,
        ...{data: this.options.data}
      })
    }
  },
  methods: {
    async createClone() {
      return new Promise(resolve => {
        const div = document.getElementById('qr-code-content'),
          clone = div.cloneNode(true)
        clone.id = 'qr-code-content-clone'
        clone.style.width = '2000px'
        clone.style.height = '2000px'
        clone.firstChild.setAttribute('style', 'padding: 96px !important')
        clone.lastChild.style.paddingLeft = '96px'
        clone.lastChild.setAttribute(
          'style',
          'font-size: 4rem' + '!important; padding-left: 96px;'
        )
        clone
          .getElementsByClassName('title-detail')[0]
          .setAttribute('style', 'font-size: 4rem' + '!important')
        clone
          .getElementsByClassName('title-detail')[1]
          .setAttribute('style', 'font-size: 4rem' + '!important')
        document.getElementsByClassName('edit-container')[0].appendChild(clone)

        clone.firstChild.removeChild(clone.firstChild.firstChild)
        clone.firstChild.id = 'qr-code-clone'

        this.qrCodeClone.append(clone.firstChild)
        resolve(clone)
      })
    },
    async download() {
      const clone = await this.createClone()
      const imgOptions = {
        width: 2000,
        height: 2000,
        scale: 1
      }
      html2canvas(clone, imgOptions).then(function(canvas) {
        const elem = document.body.appendChild(canvas)
        const image = canvas.toDataURL('image/png', 1.0)
        const link = document.createElement('a')

        link.download = 'my-image.png'
        link.href = image
        link.click()
        elem.remove()
      })

      clone.remove()
    },
    print() {
      window.print()
    }
  },
  data: () => {
    return {
      qrCode: '',
      qrCodeClone: '',
      cloneOptions: {
        width: 1200,
        height: 1200,
        data: '',
        margin: 10,
        qrOptions: {
          typeNumber: 0,
          mode: 'Byte',
          errorCorrectionLevel: 'Q'
        },
        imageOptions: {
          hideBackgroundDots: true,
          imageSize: 0.4,
          margin: 0,
          crossOrigin: 'anonymous'
        },
        dotsOptions: {
          color: '#000000'
        },
        backgroundOptions: {
          color: '#ffffff'
        },
        cornersSquareOptions: {
          color: '#000000'
        },
        cornersDotOptions: {
          color: '#000000'
        }
      }
    }
  }
}
</script>

<style scoped>
canvas {
  width: 100%;
  height: auto;
}
</style>
