<template>
  <v-container class="edit-container px-10" fluid>
    <v-popup-loading :isLoading="isLoading" />
    <alert-error
      :show="isError"
      :msg="msgError"
      :close="closeAlert"
    ></alert-error>
    <alert-confirm
      :show="isCancel"
      title="Discard changes"
      subTitle="Are you sure you want to discard all changes?"
      headline-color="black"
      buttonCancel="OK"
      buttonText="Cancel"
      :close="onCancel"
      :confirm="closeCancelPopup"
      icon=""
    ></alert-confirm>
    <v-row class="mb-4">
      <v-col>
        <v-breadcrumbs
          :items="breadcrumbs"
          divider=">"
          class="px-0"
        ></v-breadcrumbs>
        <v-row class="align-center mr-0" style="margin-left: 1px;">
          <h3 class="pageHeadline" t-data="page-headline">
            QR Code Detail
          </h3>
          <v-spacer></v-spacer>
          <edit-buttons
            :show-remove="false"
            :show-preview="false"
            :show-save="!disabledEdit"
            :on-cancel="showCancelPopup"
            :on-save="onSave"
          />
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="8">
        <v-form ref="form" lazy-validation>
          <v-card>
            <v-card-title class="mt-0 pt-0 pb-0" t-data="card-title">
              <h6 class="mb-0 card-title" t-data="info-title">
                Create QR Code Information
              </h6>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="mt-0">
              <v-row align="start" justify="start" class="mt-0 px-2 mb-6">
                <v-col cols="12" t-data="info-input">
                  <div class="body-bold primaryTextColor--text my-3">
                    {{ hospitalName }}
                  </div>
                </v-col>
              </v-row>
              <v-row align="start" justify="start" class="mt-0 px-2 mb-2">
                <v-col cols="12" t-data="info-input">
                  <span
                    class="edit-field-label sub-body-bold"
                    t-data="info-label"
                  >
                    Validation Code <span class="danger500Color--text">*</span>
                  </span>
                  <v-text-field
                    class="can-disable-selector"
                    outlined
                    dense
                    :rules="[
                      validationCodeRules.required,
                      validationCodeRules.valid
                    ]"
                    :placeholder="disabledEdit ? '' : 'Input Validation Code'"
                    v-model="qrCodeObject.validation_code"
                    :disabled="disabledEdit"
                    @input="changeValidationCode"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-form>
        <v-row class="d-flex align-start mr-0 mb-3" style="margin-left: 1px;">
          <created-detail
            v-if="isEditMode"
            :show-create="false"
            :data-object="createdDetailData"
          />
        </v-row>
      </v-col>
      <v-col cols="4">
        <v-card id="section-to-print" class="pa-6">
          <div
            v-if="isValidationCodeError"
            class="blank-qr-code sub-body-regular disableTextColor--text d-flex justify-center text-center"
            t-data="error-content"
          >
            If a QR code appears<br />
            will be displayed in this area
          </div>
          <qr-code-styling
            v-else
            :options="options"
            :row-item="qrCodeObject"
            :show-btn="true"
          ></qr-code-styling>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapState, mapActions} from 'vuex'

import {hasPermissionsByOrgs, isValidPasswordChar} from '@/helper/helper'
import AlertConfirm from '@/components/AlertConfirm'
import AlertError from '@/components/AlertError'
import CreatedDetail from '@/components/CreatedDetail'
import EditButtons from '@/components/EditButtons'
import PopupLoading from '@/components/PopupLoading'
import QrCodeStyling from '@/components/QrCodeStyling'
export default {
  components: {
    'v-popup-loading': PopupLoading,
    AlertConfirm,
    AlertError,
    CreatedDetail,
    EditButtons,
    QrCodeStyling
  },
  name: 'Edit',
  data() {
    return {
      isEditMode: false,
      isCancel: false,
      isError: false,
      msgError: '',
      isValidationCodeError: false,
      validationCodeRules: {
        required: value => {
          if (value) {
            this.isValidationCodeError = false
            return true
          }
          this.isValidationCodeError = true
          return 'Validation is required'
        },
        valid: value => {
          if (isValidPasswordChar(value)) {
            this.isValidationCodeError = false
            return true
          }
          this.isValidationCodeError = true
          return 'Unable to input Spacebar or Thai language.'
        }
      },
      options: {
        width: 300,
        height: 300,
        data: '',
        margin: 10,
        qrOptions: {
          typeNumber: 0,
          mode: 'Byte',
          errorCorrectionLevel: 'Q'
        },
        imageOptions: {
          hideBackgroundDots: true,
          imageSize: 0.4,
          margin: 0,
          crossOrigin: 'anonymous'
        },
        dotsOptions: {
          color: '#000000'
        },
        backgroundOptions: {
          color: '#ffffff'
        },
        cornersSquareOptions: {
          color: '#000000'
        },
        cornersDotOptions: {
          color: '#000000'
        }
      }
    }
  },
  computed: {
    disabledEdit() {
      return (
        this.isEditMode &&
        !hasPermissionsByOrgs('CanEditQRCode', [this.qrCodeObject.org_codename])
      )
    },
    breadcrumbs() {
      return [
        {text: 'Assistant Tool', links: true, to: '/'},
        {text: 'QR Code Management', links: true, to: '/qr-codes'},
        {text: 'QR Code Detail', links: false}
      ]
    },
    hospitalName() {
      let name = ''
      if (this.qrCodeObject.org.name) {
        name += this.qrCodeObject.org.name
      }
      if (this.qrCodeObject.org_codename) {
        name += ` (${this.qrCodeObject.org_codename.toUpperCase()})`
      }
      return name
    },
    createdDetailData() {
      return {...this.qrCodeObject, create_date: this.qrCodeObject.created_date}
    },
    ...mapState('qrCode', ['qrCodeObject', 'isLoading', 'errorMessage'])
  },
  beforeRouteLeave(to, from, next) {
    next()
    this.resetState()
    this.resetList()
  },
  async created() {
    this.isEditMode = !!this.$route.params.id
    await this.setQrCodeObject(this.$route.params.id)
    this.changeValidationCode(this.qrCodeObject.validation_code)
  },
  methods: {
    showCancelPopup() {
      this.isCancel = true
    },
    closeCancelPopup() {
      this.isCancel = false
    },
    onCancel() {
      this.$router.push({
        name: 'qr-code-list'
      })
    },
    changeValidationCode(value) {
      this.options.data = value
    },
    validate() {
      return !!this.$refs.form.validate()
    },
    async onSave() {
      if (this.validate()) {
        if (this.isEditMode) {
          const status = await this.update({
            id: this.$route.params.id,
            object: this.qrCodeObject
          })
          if (status) {
            this.$router.push({
              name: 'qr-code-list',
              query: {isCreateSuccess: true, snackTitle: 'edited'}
            })
          } else {
            this.isError = true
            this.msgError = this.errorMessage || 'Incomplete information'
          }
        }
      }
    },
    closeAlert() {
      this.isSuccess = false
      this.isError = false
    },
    ...mapActions('qrCode', [
      'resetList',
      'resetState',
      'setQrCodeObject',
      'update'
    ])
  }
}
</script>

<style scoped>
.blank-qr-code {
  margin-top: 61px;
  margin-bottom: 61px;
}
</style>
